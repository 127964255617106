const scrollTo = ({ duration = 300, element, top = 100 }) => {
	return new Promise(resolve => {
		if (typeof element === 'string') {
			window.scrollTo({
				top: document.querySelector(element).getBoundingClientRect().top + window.scrollY - top,
				behavior: 'smooth',
			})
		} else if (element) {
			window.scrollTo({
				top: element.getBoundingClientRect().top + window.scrollY - top,
				behavior: 'smooth',
			})
		}

		setTimeout(() => {
			resolve()
		}, 500)
	})
}

export default scrollTo
